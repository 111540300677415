import Vue from 'vue';
import _compact from 'lodash/compact';
import _concat from 'lodash/concat';
import _isEqual from 'lodash/isEqual';

const service = {
  async listOffers(partner_id, locale = 'fr', page, limit, categories_to_include = undefined, regions_to_include = undefined, consumer_enabled = undefined) {
    const payload = {
      include_expired: true,
      partner_ids_to_include: _compact(_concat([], partner_id)),
      regions_to_include,
      include_future_publication: true,
      consumer_enabled,
    };

    if (categories_to_include) {
      payload.categories_to_include = _compact(_concat([], categories_to_include));
    }

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers?locale=${locale}&page=${page}&limit=${limit}`, payload);
    return response.data.data.items;
  },

  async listHomeOffers(locale = 'fr', page, limit, categories_to_include = undefined, regions_to_include = undefined, statuses_to_include = undefined, consumer_enabled = undefined) {
    const payload = {
      include_expired: true,
      include_future_publication: true,
    };

    if (categories_to_include) {
      payload.categories_to_include = _compact(_concat([], categories_to_include));
    }

    if (regions_to_include) {
      payload.regions_to_include = _compact(_concat([], regions_to_include));
    }

    if (statuses_to_include) {
      payload.statuses_to_include = statuses_to_include;
    }
    payload.in_promotion = !consumer_enabled;

    let sortClause = '';
    if (_isEqual(statuses_to_include, ['coming_soon'])) {
      sortClause = '&offers_sort=coming_soon';
    }
    if (_isEqual(statuses_to_include, ['sold_out', 'closed'])) {
      sortClause = '&offers_sort=missed_offers';
    }

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v2/offers/search/home?locale=${locale}&page=${page}&limit=${limit}${sortClause}`, payload);

    const resp = {
      items: response.data.data.items,
      item_count: response.data.data.item_count,
    };

    return resp;
  },

  async getOffer(offerId) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}`);

    return response.data.data;
  },
  async getOfferCorporateDetails(offerId) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}/corporate`);

    return response.data.data;
  },
  async getOfferBySlug(slug, partnerId) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/partners/${encodeURIComponent(partnerId).substr(0, 50)}/slugs/${encodeURIComponent(slug).substr(0, 50)}`);

    return response.data.data;
  },
  async getOfferPublisher(offerId, publisherId) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}/publishers/${encodeURIComponent(publisherId).substr(0, 20)}`);
    return response.data.data;
  },
  async getOfferPublishers(offerId, locale = 'fr', page, limit, categories_to_include = undefined) {
    const payload = {};
    if (categories_to_include) {
      payload.categories_to_include = _compact(_concat([], categories_to_include));
    }
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}/publishers?locale=${locale}&page=${page}&limit=${limit}`, payload);

    return response.data.data;
  },
  async getOfferPublisherStores(publisherId, locale = 'fr', page, limit, categories_to_include = undefined, destinations_to_include = undefined) {
    const payload = {};
    if (categories_to_include) {
      payload.categories_to_include = _compact(_concat([], categories_to_include));
    }

    if (destinations_to_include) {
      payload.destinations_to_include = _compact(_concat([], destinations_to_include));
    }

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/publishers/${encodeURIComponent(publisherId).substr(0, 20)}/stores?locale=${locale}&page=${page}&limit=${limit}`, payload);
    return response.data.data;
  },
  async getOffersStores(offer_ids, locale = 'fr', page, limit, categories_to_include = undefined, destinations_to_include = undefined) {
    const payload = { offer_ids };

    if (categories_to_include) {
      payload.categories_to_include = _compact(_concat([], categories_to_include));
    }

    if (destinations_to_include) {
      payload.destinations_to_include = _compact(_concat([], destinations_to_include));
    }

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/stores?locale=${locale}&page=${page}&limit=${limit}`, payload);
    return response.data.data;
  },
  async getOfferPublisherCategoriesList(offerId, locale = 'fr') {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}/publishers/category-ids?locale=${locale}`);
    return response.data.data.items;
  },

  async getOffersStoresCategoriesList(offerIds, locale = 'fr') {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/stores/category-ids?locale=${locale}}`, { offer_ids: offerIds });

    return response.data.data.items;
  },

  async getSpaceOfferCategoriesList(partnerId, locale = 'fr') {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/partners/${encodeURIComponent(partnerId).substr(0, 20)}/category-ids?locale=${locale}`);
    return response.data.data.items;
  },

  async getOfferPublisherStoreCategoriesList(publisherId, locale = 'fr') {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/publishers/${encodeURIComponent(publisherId).substr(0, 20)}/stores/category-ids?locale=${locale}`);
    return response.data.data.items;
  },

  async redeemOfferByActivationCode(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v2/offers/activation-code/redeem`, payload);
    return response.data.data;
  },
};

export default service;
